<template>
  <div>
    <vuex-breadcrumb></vuex-breadcrumb>
    <product-model-show :id="id" @gotAttrs="assignAttrs"></product-model-show>
    <product-listing-index
      :filtering="filtering"
      :ams_template="ams_template"
    ></product-listing-index>
  </div>
</template>

<script>
import VuexBreadcrumb from './VuexBreadcrumb.vue'
import ProductModelShow from './ProductModelShow.vue'
import ProductListingIndex from './ProductListingIndex.vue'

export default {
  components: {
    VuexBreadcrumb,
    ProductModelShow,
    ProductListingIndex
  },
  props: {
    id: String
  },
  data () {
    return {
      filtering: {
        product_model_ids: this.id
      },
      ams_template: {
        type: 'product-listings',
        description: undefined,
        attributes: {
          'product-model-id': this.id,
          'price-cents': 0
        }
      },
      attrs: {},
    }
  },
  methods: {
    assignAttrs(attrs, description) {
      this.attrs = Object.assign( {}, attrs)
      this.ams_template.description = description + ' ' + this.attrs['name']
    }
  }
}
</script>